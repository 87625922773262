<template>
  <div>
    <Logout :trigger-logout="triggerLogout" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

import Logout from '../Login/Logout.vue';

import { isTokenExpired, refresh_keycloak_token } from '@/axiosInstance';

const WEBSOCKET_URL = !import.meta.env.PROD
  ? 'ws://localhost/ws'
  : 'wss://' + document.location.host + ':' + document.location.port + '/ws';
const HEARTBEAT_RATE = 20000;
const THRESHOLD_RATE = 2500;
export default {
  name: 'Websocket',

  components: { Logout },

  data() {
    return {
      websocketInstance: null,
      heartbeatTimer: null,
      thresholdTimer: null,
      triggerLogout: false,
      websocketFailureCounter: 0,
    };
  },

  computed: {
    ...mapState(['part', 'isAuthenticated']),
    ...mapState('application', ['axiosInstance']),
  },

  mounted() {
    // Starting Websocket connection if valid tokens exist
    this.$store.subscribe(mutation => {
      //every time tokens are set (on login) the websockets are reopened
      if (mutation.type === 'setToken') {
        this.reopenWebsocket();
      } else if (mutation.type === 'removeTokens') {
        this.closeWebsocket();
      }
    });
    //also the heartbeat is reset so if the connections dies, a new connection can be established.
    this.resetHeartbeat();
  },

  methods: {
    ...mapMutations([
      'updatedPartLibraryData',
      'updateFetchPartLibraryDump',
      'updateFetchAnalysisResultsXLS',
      'updateFetchCustomReportDOCX',
      'updateFetchCustomListReportDOCX',
      'updateFetchOptimalOrientation',
      'updatePartScaling',
      'changeInvestigationDetails',
      'updateProcessChainFromTemplate',
      'updateActiveId',
      'updateCadStat',
      'updateCadUploadProgress',
      'updateAssetStat',
      'updateProcessChainStatus',
      'updateProcessChain',
      'updatePart',
      'addDrawingAnalsysisResults',
      'setCurrentMat',
      'setCurrentMatAnalysis',
      'setPartUploadError',
    ]),

    ...mapMutations('prp', ['setPrpPartAnalysisDone', 'setProcessChainUpdated']),
    ...mapMutations('canvas', ['updateReloadCanvas']),
    ...mapMutations('application', ['setWebsocketDisconnect', 'setWebsocketFailure']),
    ...mapActions(['setPartsData']),

    logout() {
      this.triggerLogout = true;
    },

    checkSamePartOrNoPartId(incomingPartId) {
      if (this.part?.part_id === incomingPartId || this.part?.part_id === '') {
        return true;
      } else {
        return false;
      }
    },

    handleWebsocketEvent(event) {
      /*
      Handling input awaited by the websockets
      */
      let json_event = JSON.parse(event.data);
      let task = json_event['task'];
      let incomingPartId = json_event['part_id'];

      // Notification handling
      // Create popup notification sent by 'send_notification()'
      if ('notification' in json_event) {
        this.$root.notify(json_event['type'], json_event['title'], json_event['message'], json_event['duration']);
      }

      let notifyResponse = false; // default
      if (json_event['status'] !== undefined) {
        // fallback: skip if task was not decorated with spark_celery_wrapper
        if (json_event['status'] === 'error') {
          notifyResponse = true; // always notify on errors
        }
      }

      // Update if part_ids match or part does not have a part_id yet
      // set notifyResponse to true if user should be informed via popup about incoming data
      if (this.checkSamePartOrNoPartId(incomingPartId)) {
        switch (task) {
          // Update part info and load part in canvas on file conversion
          case 'convert_file':
            this.updatePartWithNewData(json_event);
            this.updateActiveId(json_event.part_id);
            this.updateReloadCanvas(true);
            notifyResponse = true;
            break;

          // Update cad_stat
          case 'cad_stat_update':
            this.updateCadStat(json_event['cad_stat']);
            break;

          case 'cad_upload_progress':
            this.updateCadUploadProgress(json_event['progress']);
            break;

          // drawing analysis results - update material
          case 'drawing_analysis_task':
            this.addDrawingAnalsysisResults(json_event?.drawing_analysis);
            this.setCurrentMatAnalysis(json_event.current_mat_analysis);
            notifyResponse = true;
            break;

          // current material results
          case 'current_material_proposals':
            this.setCurrentMatAnalysis(json_event.material_proposals);
            notifyResponse = false;
            break;

          // Update asset_stat
          case 'asset_stat_update':
            this.updateAssetStat(json_event);
            break;

          case 'process_chain_status':
            this.updateProcessChainStatus(json_event.process_chains);
            break;

          case 'calculate_single_process_chain':
            this.updateProcessChain(json_event);
            break;

          case 'calculate_feedback_process_chain_from_step':
            this.updateProcessChain(json_event);
            this.updatePartWithNewData(json_event.part);
            break;

          case 'calculate_feedback_process_chain':
            this.updateProcessChain(json_event);
            break;

          case 'prp_finished':
            this.setPrpPartAnalysisDone(true);
            break;

          case 'create_and_calculate_process_chain_from_template':
            this.updateProcessChainFromTemplate(json_event.process_chains);
            this.updatePartWithNewData(json_event.part);
            break;

          case 'create_and_calculate_process_chains_from_analysis_profile':
            this.updateProcessChainFromTemplate(json_event.process_chains);
            break;

          case 'initializing_process_chain':
          case 'calculate_all_process_chains':
            this.updatePartWithNewData(json_event.part);
            this.updateProcessChain(json_event);
            this.setProcessChainUpdated(true);
            break;
          case 'compute_analysis_maps_of_mesh':
          case 'calculate_thickness':
          case 'build_mesh':
            this.updateReloadCanvas(true);
            break;
          case 'cost_optimization':
            this.updatePartWithNewData(json_event);
            break;

          case 'compute_process_proposal':
            this.updatePartWithNewData(json_event);
            break;

          case 'finalize_analysis':
            this.updateProcessChain(json_event);
            this.updatePartWithNewData(json_event.part);
            this.changeInvestigationDetails({ uid: this.part.primary_process_chain_id, content: '' });
            notifyResponse = true;
            break;

          case 'apply_new_units_on_mesh_vertices':
            this.updatePartScaling(json_event);
            break;

          default:
            break;
        }
      }

      // Handle files requested by user
      switch (task) {
        case 'apply_optimal_orientation_and_store_file_STL':
        case 'apply_optimal_orientation_and_store_file_STEP':
          this.updateFetchOptimalOrientation(true);
          break;

        case 'write_and_store_analysis_results_XLS':
          this.updateFetchAnalysisResultsXLS(true);
          break;

        case 'create_custom_report_docx':
          var payload = {
            part_id: json_event.part_id,
            part_name: json_event.part_name,
            template_uid: json_event.template_uid,
            default_file_name: json_event.default_file_name,
          };
          this.updateFetchCustomReportDOCX(JSON.stringify(payload));
          break;

        case 'create_custom_list_report_docx':
          this.updateFetchCustomListReportDOCX(true);
          break;

        case 'write_and_store_part_library_XLS':
          this.updateFetchPartLibraryDump(true);
          break;

        default:
          break;
      }

      // Handle Websocket heartbeat event
      if ('heartbeat' in json_event) {
        this.resetHeartbeat();
      }

      // user notification
      else if (notifyResponse) {
        let part_str = 'Part: ' + json_event['part_name'];

        let notification_str = json_event['user_notification'];
        if (json_event['status'] === 'error') {
          this.setPartUploadError(json_event['part_id']);
          notification_str += '\n' + json_event['error_message'];
        }

        this.$root.notify(json_event['status'], part_str, notification_str, 3000);
      }
    },

    closeWebsocket() {
      if (this.websocket_is_alive()) {
        this.websocketInstance.send('CLOSING');
        this.websocketInstance.close();
      }
      clearTimeout(this.heartbeatTimer);
      clearTimeout(this.thresholdTimer);
    },

    createWebsocket(url) {
      try {
        // check for token and authentication-status. Moved check for !websocketInstance down a step bc it wouldn't reconnect due to closed wsInstance being present.
        if (this.$keycloak.token && this.$keycloak.authenticated) {
          this.websocketInstance = new WebSocket(url + '/echo/?Bearer=' + this.$keycloak.token);
          this.websocketInstance.onmessage = this.handleWebsocketEvent;

          this.websocketInstance.onopen = this.handleWebsocketConnectionSuccess;

          this.websocketInstance.onerror = this.handleWebsocketConnectionError;
        } else {
          this.resetHeartbeat();
        }
      } catch (error) {
        console.error(error);
      }
    },

    reopenWebsocket() {
      try {
        this.closeWebsocket();
        if (this.$keycloak.authenticated && !isTokenExpired(this.$keycloak.token)) {
          this.createWebsocket(WEBSOCKET_URL);
          this.setWebsocketDisconnect(true);
        } else {
          this.resetHeartbeat();
        }
      } catch (error) {
        console.error(error);
      }
    },

    handleWebsocketConnectionTimeout() {
      this.reopenWebsocket();
    },

    handleWebsocketConnectionError() {
      console.error('Cannot reach server.');
      this.websocketConnectionFailureCounter();
      this.resetHeartbeat(3000);
    },

    handleWebsocketConnectionSuccess() {
      this.websocketConnectionFailureCounter(true);
      this.resetHeartbeat();
    },

    websocketConnectionFailureCounter(reset = false) {
      if (reset) {
        this.websocketFailureCounter = 0;
        this.setWebsocketFailure(false);
        return;
      }
      this.websocketFailureCounter++;
      if (this.websocketFailureCounter >= 5) {
        this.setWebsocketFailure(true);
      }
    },

    websocket_is_alive() {
      // readyState 2: closing, readyState 3: closed
      return this.websocketInstance != null && this.websocketInstance.readyState == 1;
    },

    handleTimeout() {
      if (this.$route.meta.requireLogin === false && !this.$keycloak.authenticated) {
        // if the user is not logged in and the route does not require login, do nothing
        return;
      }
      refresh_keycloak_token(this.$keycloak).then(keycloak => {
        if (keycloak.authenticated) {
          if (this.websocket_is_alive()) {
            this.websocketInstance.send(keycloak.token);
          }
          this.thresholdTimer = setTimeout(this.handleWebsocketConnectionTimeout, THRESHOLD_RATE);
        } else {
          console.error('Not authenticated!');
          this.logout();
        }
      });
    },

    resetHeartbeat(rate = HEARTBEAT_RATE) {
      /*
      Heartbeat for websocket connection is always running
      If the heartbeatTime runs of, a threshold timer is started and the heartbeat timer is restarted.
      Then the client sends a message over the websocket to the server.
      The server answers (defined in the function websocket_receive in websocket_consumers.py).
      If the answer arrives back to the client (definied in handleWebsockets) nothing happens (because the heartbeat timer is already running).
      If no answer arrives in time the thresholdtimer goes off and the websocket is reopened.

      */
      if (!this.heartbeatTimer) {
        setTimeout(() => this.reopenWebsocket(), 5000);
      }
      clearTimeout(this.heartbeatTimer);
      clearTimeout(this.thresholdTimer);
      this.heartbeatTimer = setTimeout(this.handleTimeout, rate);
    },

    updatePartWithNewData(json_event) {
      if (this.part.part_id == json_event['part_id'] || this.part.part_id == '0') {
        let newPart = this.part;
        let keys = Object.keys(json_event);
        keys.forEach(key => {
          newPart[key] = json_event[key];
        });
        this.updatePart(newPart);
      }
    },
  },
};
</script>
