const feasData = {
  fits_mac: {
    name: 'Fits Machine',
    additionalInfo: 'Does part fit inside the machine envelope without protruding.',
    showBar: false,
  },
  size: {
    name: 'Fits Process',
    additionalInfo: 'Are bounding box dimensions of part suited for manufacturing process.',
    showBar: true,
  },
  thickness: {
    name: 'Wall Thickness',
    additionalInfo: 'Is wall thickness distribution of part suitable.',
    showBar: true,
  },
  gap_size: {
    name: 'Gap Size',
    additionalInfo: 'Are the gaps between walls small enough.',
    showBar: true,
  },
  support_occluded: {
    name: 'Support Removability',
    additionalInfo: 'Are supports sufficiently reachable for removal.',
    showBar: false,
  },
  radii: {
    name: 'Sharp Edges',
    additionalInfo: 'Are edge radii big enough.',
    showBar: true,
  },
  thickness_mean: {
    name: 'Mean Wall Thickness',
    additionalInfo: 'Is mean wall thickness big enough.',
    showBar: true,
  },
};

export default function parseFeasDict(feasJson) {
  let flatDict = {};
  Object.entries(feasJson).forEach(([feasKey, feasValue]) => {
    if (feasEntryIsCheck(feasKey)) {
      let minMaxPresent = minAndMaxPresent(feasValue);

      flatDict[feasKey] = {
        name: feasData[feasKey].name,
        additionalInfo: feasData[feasKey].additionalInfo,
        minAndMaxPresent: minMaxPresent,
      };

      Object.entries(feasValue).forEach(([minMaxKey, minMaxValue]) => {
        let limitIsSet = checkhasValidLimit(feasJson, feasKey, minMaxKey);
        if (minMaxPresent) {
          flatDict[feasKey][minMaxKey] = minMaxValue;
          flatDict[feasKey][minMaxKey]['limitIsSet'] = limitIsSet;
        } else {
          flatDict[feasKey] = {
            ...flatDict[feasKey],
            ...minMaxValue,
            limitIsSet: limitIsSet,
            minOrMax: minMaxKey,
          };
        }
      });
    }
  });
  return flatDict;
}

function feasEntryIsCheck(feasKeyOfBackendJson) {
  return feasKeyOfBackendJson in feasData;
}

function minAndMaxPresent(feasDictValue) {
  return Object.keys(feasDictValue).length > 1;
}

function checkhasValidLimit(feasJson, feasKey, minMaxKey) {
  const check = feasJson[feasKey][minMaxKey];
  const isValid = check?.lim !== null;
  return isValid;
}

export function getWeightString(weightNumber) {
  if (weightNumber == 0) {
    return 'Neglected';
  } else if (weightNumber == 0.5) {
    return 'Low';
  } else if (weightNumber == 1) {
    return 'Regular';
  } else if (weightNumber == 10) {
    return 'High';
  } else if (weightNumber == 1000) {
    return 'Crucial';
  }
}
