<template>
  <div v-if="loadModelCompleted" class="flex flex-wrap max-h-full justify-between bg-white">
    <!-- Centering -->
    <div class="flex flex-row gap-8">
      <CanvasButton
        v-show="loadModelCompleted && partIdExisting"
        :icon="zoomIcon"
        text="Zoom"
        added-class="accent-border-hover"
        @button-clicked="resetToDefaultView()"
      />

      <!-- Transparency -->
      <CanvasButton
        v-show="loadModelCompleted"
        :icon="xrayIcon"
        class="margin-right"
        :clicked="transparencyToggle"
        added-class="accent-border-hover"
        text="XRay"
        @button-clicked="toggleTransparency(!transparencyToggle)"
      />

      <!-- Measurement Grid -->
      <CanvasButton
        v-show="loadModelCompleted"
        :icon="gridIcon"
        class="margin-right"
        :clicked="showGrid"
        text="Measurement Grid"
        added-class="accent-border-hover"
        :has-options="false"
        @button-clicked="updateShowGrid(!showGrid)"
      />

      <DropdownMenu
        v-if="highlightedProcessChain"
        class="pl-16 pr-0"
        :element-options="dropdownOptions"
        canvasactions
        custom
        custom-class="flex items-center border hover:border hover:border-primary-600 rounded-8"
        show-caret
        close-click-style="outside"
        @update-option="setOption($event)"
      >
        <CanvasButton
          v-show="highlightedProcessChain"
          :icon="machineIcon"
          class="margin-right"
          text="Visualization Options"
          has-options
        />
      </DropdownMenu>
      <DropdownMenu
        v-if="highlightedProcessChain"
        class="pl-16 pr-0"
        :element-options="feasibilityOptions"
        custom-class="flex items-center border hover:border hover:border-primary-600 rounded-8"
        canvasactions
        custom
        show-caret
        close-click-style="outside"
        @update-option="setOption($event)"
      >
        <CanvasButton
          v-show="highlightedProcessChain"
          :icon="activeCheckIcon"
          class="margin-right"
          text="Feasibility Checks"
          has-options
        />
        <div v-if="!allFeasAreFine" class="circle"><img src="@/assets/icons/canvas/alert-circle.svg" /></div>
      </DropdownMenu>
    </div>
  </div>
</template>

<script>
import {
  BoxGeometry,
  MeshBasicMaterial,
  EdgesGeometry,
  PlaneGeometry,
  Mesh,
  Group,
  DoubleSide,
  LineBasicMaterial,
  LineSegments,
} from 'three';
import { mapState, mapMutations, mapGetters } from 'vuex';

import DropdownMenu from '../../Reusable/DropdownMenu.vue';

import CanvasButton from './CanvasButton.vue';

import DotsIcon from '@/assets/icons/canvas/dots_horizontal.svg';
import FitsMachineIcon from '@/assets/icons/canvas/fits_machine_n.svg';
import GapSizeIcon from '@/assets/icons/canvas/gap_size_n.svg';
import GridIcon from '@/assets/icons/canvas/grid.svg';
import MachineIcon from '@/assets/icons/canvas/machine_n.svg';
import NestingIcon from '@/assets/icons/canvas/nesting_n.svg';
import OrientationIcon from '@/assets/icons/canvas/orientation_n.svg';
import PackageIcon from '@/assets/icons/canvas/package.svg';
import FeasibilityMainIcon from '@/assets/icons/canvas/receipt-check.svg';
import RemoveabilityIcon from '@/assets/icons/canvas/removeability_n.svg';
import SharpEdgesIcon from '@/assets/icons/canvas/sharp_edges.svg';
import SupportsIcon from '@/assets/icons/canvas/supports_n.svg';
import ThicknessIcon from '@/assets/icons/canvas/thickness_n.svg';
import XRayIcon from '@/assets/icons/canvas/x_ray_n.svg';
import ZoomIcon from '@/assets/icons/canvas/zoom_n.svg';
import CheckIcon from '@/assets/icons/feasibility/check.svg';
import WarningIcon from '@/assets/icons/feasibility/warning.svg';
import { analysisFinished } from '@/composables/processChainHandling.js';
import { totalFeasFromObj } from '@/helpers.js';

export default {
  name: 'BarButtons',

  components: {
    CanvasButton,
    DropdownMenu,
  },

  props: {
    viewScaleFactor: { type: Number, default: 1 },
    physicalPartScaleZ: { type: Number, default: 1 },
    loadModelCompleted: Boolean,
    plotMachineEnvelopeWhenLoadingModelIsFinished: Boolean,
    plateOffsetToFocusPartInMiddleOfCanvas: { type: Number, default: 0 },
  },

  emits: ['toggle-build-envelope', 'reset-to-default-view', 'update-color-in-canvas', 'edit-orientation'],

  data() {
    return {
      activeAttribute: 'geometry',
      buildEnvelopeToggler: false,
    };
  },

  computed: {
    ...mapState(['part', 'investigationDetails', 'user']),
    ...mapState('application', ['axiosInstance']),
    ...mapState('canvas', ['canvas', 'feasibilityToggle']),
    ...mapGetters(['fileGeometricPropertiesFinished', 'processChainsAnalysisRunning']),

    activeCheckIcon() {
      if (this.activeAttribute === '_support') {
        return this.supportsIcon;
      } else if (this.activeAttribute === '_support_removability') {
        return this.removeabilityIcon;
      } else if (this.activeAttribute === '_fits_machine') {
        return this.fitsmachineIcon;
      } else if (this.activeAttribute === '_thickness_min') {
        return this.thicknessIcon;
      } else if (this.activeAttribute === '_thickness_max') {
        return this.thicknessIcon;
      } else if (this.activeAttribute === '_gap_size') {
        return this.gapsizeIcon;
      } else if (this.activeAttribute === '_radii') {
        return this.sharpEdgesIcon;
      } else if (this.allFeasAreFine) {
        return this.checkIcon;
      } else {
        return this.warningIcon;
      }
    },

    warningIcon() {
      return WarningIcon;
    },

    checkIcon() {
      return CheckIcon;
    },

    sharpEdgesIcon() {
      return SharpEdgesIcon;
    },

    zoomIcon() {
      return ZoomIcon;
    },

    packageIcon() {
      return PackageIcon;
    },

    xrayIcon() {
      return XRayIcon;
    },

    gridIcon() {
      return GridIcon;
    },

    machineIcon() {
      return MachineIcon;
    },

    orientationIcon() {
      return OrientationIcon;
    },

    feasibilityMainIcon() {
      return FeasibilityMainIcon;
    },

    nestingIcon() {
      return NestingIcon;
    },

    supportsIcon() {
      return SupportsIcon;
    },

    fitsmachineIcon() {
      return FitsMachineIcon;
    },

    removeabilityIcon() {
      return RemoveabilityIcon;
    },

    thicknessIcon() {
      return ThicknessIcon;
    },

    gapsizeIcon() {
      return GapSizeIcon;
    },

    dotsIcon() {
      return DotsIcon;
    },

    showFeasibilityButton() {
      return this.loadModelCompleted && !this.processChainsAnalysisRunning;
    },

    highlightedProcessChainUid() {
      return this.investigationDetails.uid;
    },

    highlightedProcessChain() {
      if (this.highlightedProcessChainUid != null || this.highlightedProcessChainUid != '') {
        return this.processChains[this.highlightedProcessChainUid];
      }
      return null;
    },

    analysisFinished() {
      return analysisFinished(this.highlightedProcessChainUid);
    },

    processChains() {
      return this.part.process_chains;
    },

    highlightedProcessChainIsAM() {
      if (this.highlightedProcessChain?.tech == 'additive_manufacturing') {
        return true;
      } else {
        return false;
      }
    },

    partId() {
      return this.part.part_id;
    },

    rotStat() {
      return this.canvas.rot_stat;
    },

    showNesting() {
      return this.canvas.showNesting;
    },

    transparencyToggle() {
      return this.canvas.transparency;
    },

    showGrid() {
      return this.canvas.showGrid;
    },

    partIdExisting() {
      return this.partId != 0;
    },

    initializedOrAnalyzed() {
      return (
        this.highlightedProcessChain?.status == 'initialized' ||
        this.highlightedProcessChain?.status == 'analyzed' ||
        this.highlightedProcessChain?.status == 'finished'
      );
    },

    cadUploaded() {
      return this.basename != '';
    },

    feasibilityOptions() {
      let options = { 'Feasibility Checks': [] };
      if (this.loadModelCompleted && this.initializedOrAnalyzed) {
        if (this.highlightedProcessChainIsAM) {
          options['Feasibility Checks'].push({
            text: 'Supported areas',

            image: this.supportsIcon,
            value: 'toggleSupport',
            route: false,
          });
        }
        if (this.highlightedProcessChain?.feasibility?.fits_mac?.max?.chk != undefined) {
          options['Feasibility Checks'].push({
            text: 'Fits machine',
            icon: this.getRadio(this.activeAttribute === '_fits_machine'),
            value: 'toggleFitsMachine',
            route: false,
            image: this.fitsmachineIcon,
            warning: !this.highlightedProcessChain?.feasibility?.fits_mac?.max?.chk,
          });
        }
        if (
          this.highlightedProcessChainIsAM &&
          this.highlightedProcessChain?.feasibility?.support_occluded?.min?.chk != undefined
        ) {
          options['Feasibility Checks'].push({
            text: 'Support Removeability',
            image: this.removeabilityIcon,
            value: 'toggleSupportRemoveability',
            route: false,
            warning: !this.highlightedProcessChain?.feasibility?.support_occluded?.min?.chk,
          });
        }
      }
      if (this.showFeasibilityButton && this.highlightedProcessChain?.feasibility?.thickness?.min?.chk != undefined) {
        options['Feasibility Checks'].push({
          text: 'Min Thickness',
          icon: this.getRadio(this.activeAttribute === '_thickness_min'),
          value: 'toggleThicknessMin',
          route: false,
          image: this.thicknessIcon,
          warning: !this.highlightedProcessChain?.feasibility?.thickness?.min?.chk,
        });
      }
      if (this.showFeasibilityButton && this.highlightedProcessChain?.feasibility?.thickness?.max?.chk != undefined) {
        options['Feasibility Checks'].push({
          text: 'Max Thickness',
          icon: this.getRadio(this.activeAttribute === '_thickness_max'),
          value: 'toggleThicknessMax',
          route: false,
          image: this.thicknessIcon,
          warning: !this.highlightedProcessChain?.feasibility?.thickness?.max?.chk,
        });
      }
      if (this.showFeasibilityButton && this.highlightedProcessChain?.feasibility?.gap_size?.min?.chk != undefined) {
        options['Feasibility Checks'].push({
          text: 'Gap Size',
          icon: this.getRadio(this.activeAttribute === '_gap_size'),
          value: 'toggleGapSize',
          route: false,
          image: this.gapsizeIcon,
          warning: !this.highlightedProcessChain?.feasibility?.gap_size?.min?.chk,
        });
      }
      if (this.showFeasibilityButton && this.highlightedProcessChain?.feasibility?.radii?.min?.chk != undefined) {
        options['Feasibility Checks'].push({
          text: 'Sharp Edges',
          icon: this.getRadio(this.activeAttribute === '_radii'),
          value: 'toggleRadii',
          route: false,
          image: this.sharpEdgesIcon,
          warning: !this.highlightedProcessChain?.feasibility?.radii?.min?.chk,
        });
      }
      return options;
    },

    dropdownOptions() {
      let options = { Machine: [], Orientation: [] };
      options['Machine'].push({
        text: 'Show machine',
        icon: this.getIcon(this.buildEnvelopeToggler),
        image: this.machineIcon,
        disabled: !this.loadModelCompleted || !this.initializedOrAnalyzed || !this.highlightedProcessChain,
        warning: false,
        value: 'toggleBuild',
        route: false,
      });
      options['Machine'].push({
        text: 'Show nesting',
        icon: this.getIcon(this.showNesting),
        value: 'toggleNestingFunction',
        route: false,
        image: this.nestingIcon,
        border: true,
        disabled: this.user.is_external || !this.showFeasibilityButton || !this.highlightedProcessChainIsAM,
      });
      options['Orientation'].push({
        text: 'Show optimal Orientation',
        icon: this.getIcon(this.rotStat === 'rot_cost'),
        value: 'orientationToggle',
        route: false,
        border: true,
        image: this.orientationIcon,
        disabled:
          this.user.is_external ||
          !this.loadModelCompleted ||
          !this.analysisFinished ||
          !this.highlightedProcessChainIsAM,
      });
      options['Orientation'].push({
        text: 'Edit orientation',
        icon: 'button',
        border: true,
        value: 'editOrientation',
        disabled: this.user.is_external || !this.highlightedProcessChainIsAM,
      });

      return options;
    },

    allFeasAreFine() {
      if (this.highlightedProcessChain?.feasibility !== undefined) {
        // eslint-disable-next-line no-unused-vars
        const { size, ...reducedFeasObj } = this.highlightedProcessChain.feasibility; // exclude size check as it cannot be displayed in canvas anyway
        return totalFeasFromObj(reducedFeasObj);
      }
      return true;
    },
  },

  watch: {
    highlightedProcessChain: function () {
      this.resetBar();
    },

    feasibilityToggle: function (newAttribute) {
      this.toggleFeasibility(newAttribute);
    },

    partId: function () {
      this.resetBar();
    },

    plotMachineEnvelopeWhenLoadingModelIsFinished: function (newVal) {
      if (newVal == true) {
        this.resetBar();
      }
    },

    cadUploaded(newVal) {
      if (newVal) {
        this.resetToDefaultView();
      }
    },

    analysisFinished(newVal) {
      if (newVal) {
        this.orientationToggle('rot_cost');
      }
    },
  },

  methods: {
    ...mapMutations('canvas', ['updateDisplayNesting', 'updateRotStat', 'updateTransparency', 'updateShowGrid']),

    toggleSupport() {
      this.toggleFeasibility('_support');
    },

    toggleFitsMachine() {
      this.toggleFeasibility('_fits_machine');
    },

    toggleSupportRemoveability() {
      this.toggleFeasibility('_support_removability');
    },

    toggleThicknessMin() {
      this.toggleFeasibility('_thickness_min');
    },

    toggleThicknessMax() {
      this.toggleFeasibility('_thickness_max');
    },

    toggleGapSize() {
      this.toggleFeasibility('_gap_size');
    },

    toggleRadii() {
      this.toggleFeasibility('_radii');
    },

    setOption(event) {
      this[event.value]();
    },

    getIcon(bool) {
      if (bool) {
        return 'far fa-check-square';
      } else {
        return 'far fa-square';
      }
    },

    getRadio(bool) {
      if (bool) {
        return 'far fa-check-circle';
      } else {
        return 'far fa-circle';
      }
    },

    editOrientation() {
      this.$emit('edit-orientation');
    },

    resetBar() {
      // reset feasibility color representation and orientation
      this.activeAttribute = 'geometry';
      this.updateRotStat(this.rotStat);

      // reset build envelope on material change
      this.buildEnvelopeToggler = false; // provokes the machine envelope to be shown
      this.toggleNesting(false);
      this.toggleBuild();
    },

    toggleBuild(notify = false) {
      let chain = this.highlightedProcessChain;

      if (chain == undefined || !this.initializedOrAnalyzed) {
        if (notify) this.processWarning();
        return;
      }

      if (this.initializedOrAnalyzed) {
        this.buildEnvelopeToggler = !this.buildEnvelopeToggler;

        const viewScaledExtendMachine = [
          chain.machine_bld_size_x * this.viewScaleFactor,
          chain.machine_bld_size_y * this.viewScaleFactor,
          chain.machine_bld_size_z * this.viewScaleFactor,
        ];
        const wireFrame = this.createEnvelopeWireframe(viewScaledExtendMachine);
        const plateMesh = this.createPlateMesh(viewScaledExtendMachine);
        const envelope = this.createWholeMachine(wireFrame, plateMesh, viewScaledExtendMachine);

        this.$emit('toggle-build-envelope', {
          build: envelope,
          deleteBool: !this.buildEnvelopeToggler,
        });
      } else {
        if (notify) this.processWarning();
        return;
      }
    },

    createEnvelopeWireframe(viewScaledExtendMachine) {
      const boxCenteredAtOrigin = new BoxGeometry(...viewScaledExtendMachine);
      const wireframe = new LineSegments(
        new EdgesGeometry(boxCenteredAtOrigin),
        new LineBasicMaterial({ color: 0x000000 })
      );
      return wireframe;
    },

    createPlateMesh(viewScaledExtendMachine) {
      let planeGeom = new PlaneGeometry(viewScaledExtendMachine[0], viewScaledExtendMachine[1]);
      const material = new MeshBasicMaterial({
        color: 0xcccccc,
        transparent: true, // needed for the part to be visible if part is toggled transparent
        opacity: 0.5, // plate is not hiding part when viewing from below
        depthWrite: false, // prevents plate to turn opaque from certain view angles
        side: DoubleSide, // plate is colored from both sides (top and down)
      });
      const plateMesh = new Mesh(planeGeom, material);
      plateMesh.renderOrder = 3;

      plateMesh.position.z = -viewScaledExtendMachine[2] / 2; // move plate to bottom of envelope
      plateMesh.position.z -= 1; // to avoid aliasing when part lies (flatly) on plate
      return plateMesh;
    },

    createWholeMachine(wireFrame, plateMesh, viewScaledExtendMachine) {
      const envelope = new Group();
      envelope.name = 'build_envelope';
      envelope.add(wireFrame);
      envelope.add(plateMesh);

      let zOffsetBottomToOrigin = viewScaledExtendMachine[2] / 2;
      let zOffsetBottomFromOriginToGivenPlateOffset =
        this.plateOffsetToFocusPartInMiddleOfCanvas * this.viewScaleFactor * this.physicalPartScaleZ;
      envelope.position.z = zOffsetBottomToOrigin + zOffsetBottomFromOriginToGivenPlateOffset;
      return envelope;
    },

    processWarning() {
      this.$root.notify('warning', 'Not available', 'No process selected.', 3000);
    },

    orientationToggle(newToggleState) {
      let buttonWasToggledManually = newToggleState == undefined;
      if (buttonWasToggledManually) {
        var manuallyToggleOn = this.rotStat != 'rot_cost';
      }
      if (newToggleState || manuallyToggleOn) {
        this.updateRotStat('rot_cost');
      } else {
        this.updateRotStat('rot_user');
        this.updateDisplayNesting(false);
      }
    },

    toggleNestingFunction() {
      let newToggleState = !this.showNesting;
      this.toggleNesting(newToggleState);
    },

    toggleNesting(newToggleState) {
      const nestings_pos_rot = this.highlightedProcessChain?.nestings_pos_rot;
      const tooManyNestingsToDisplay = typeof nestings_pos_rot == 'string';
      if (newToggleState == true && tooManyNestingsToDisplay) {
        this.$root.notify('warning', 'Nesting Visualization', nestings_pos_rot, 3000);
        this.updateDisplayNesting(false);
      } else {
        this.orientationToggle(newToggleState);
        this.updateDisplayNesting(newToggleState);
        if (newToggleState) {
          this.updateTransparency(false); // standard transparency must be toggled off for nestings
          this.toggleFeasibility('nesting_toggled'); // turn off showing supports, thickness, ...
        }
      }
    },

    toggleFeasibility(attribute) {
      if (!this.initializedOrAnalyzed) {
        this.$root.notify('warning', 'Not available', 'Feasibility not calculated yet.', 3000);
      } else {
        let activeButtonGotDeselected = attribute === this.activeAttribute;
        let nestingIsAcivated = attribute === 'nesting_toggled';

        if (activeButtonGotDeselected || nestingIsAcivated) {
          this.activeAttribute = 'geometry';
        } else {
          // standard case
          this.activeAttribute = attribute;
          this.updateDisplayNesting(false);
        }

        this.$emit('update-color-in-canvas', this.activeAttribute);
      }
    },

    resetToDefaultView() {
      this.$emit('reset-to-default-view', this.activeAttribute);
    },

    toggleTransparency(newToggleState) {
      this.updateTransparency(newToggleState);
      this.$emit('update-color-in-canvas', this.activeAttribute);
    },
  },
};
</script>

<style lang="scss" scoped>
.circle {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 17px;
}
</style>
