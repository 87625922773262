<template>
  <div class="flex items-center justify-between">
    <div v-if="weight !== null" class="text-11 text-gray-400 ml-4 pr-16">Priority - {{ weightString }}</div>
    <div class="text-13 font-bold ml-4" :class="textColor">{{ percentage }}%</div>
    <PercentagePieChart :percentage="percentage" :stroke-color="strokeColor" />
  </div>
</template>

<script>
import { getWeightString } from '@/components/PartWorkflow/Feasibility/FeasibilityParsing';
import PercentagePieChart from '@/components/PartWorkflow/Feasibility/PercentagePieChart.vue';

export default {
  name: 'FeasibilityValueAndPieChart',

  components: {
    PercentagePieChart,
  },

  props: {
    percentage: {
      type: Number,
      required: true,
      validator: value => value >= 0 && value <= 100,
    },

    weight: {
      type: Number,
      default: null,
    },
  },

  computed: {
    colorClass() {
      if (this.percentage < 75) {
        return 'red-500';
      } else if (this.percentage < 98) {
        return 'orange-400'; // -500 does not work
      } else {
        return 'green-500';
      }
    },

    strokeColor() {
      const colors = {
        'red-500': '#DE5852',
        'orange-400': '#F37F00',
        'green-500': '#468641',
      };
      return colors[this.colorClass];
    },

    textColor() {
      return `text-${this.colorClass}`;
    },

    weightString() {
      return getWeightString(this.weight);
    },
  },
};
</script>
