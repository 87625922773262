<template>
  <div class="flex h-full w-full flex-col mx-12 text-13">
    <p class="font-bold text-15">Market Price</p>

    <div class="border border-gray-50 rounded-8 my-4">
      <div class="flex flex-row">
        <div class="w-1/3 text-gray-500 px-8">Gross Margin</div>
        <div class="w-2/3 pl-10">{{ grossMargin }}%</div>
      </div>
      <div class="flex flex-row">
        <div class="w-1/3 text-gray-500 px-8">Pricing Config</div>
        <div class="w-2/3 pl-10">{{ usedPricingConfig.name }}</div>
      </div>
      <hr class="border-gray-50" />
      <div class="flex flex-row">
        <div class="w-1/3 p-8">Cost</div>
        <div class="w-1/3 pl-10">{{ $formatPrice(amCostPerPart) }} <span class="text-gray-500">per Part</span></div>
        <div class="w-1/3 pl-10">{{ $formatPrice(amCostPerLot) }} <span class="text-gray-500">per Lot</span></div>
      </div>

      <div class="flex flex-row">
        <div class="p-8 w-1/3">Price</div>
        <div class="flex w-1/3">
          <FeedbackValue
            :feedback-value="marketPricePerPart"
            no-heading
            field-name=""
            field-unit="€"
            field-type="validator"
            field-style="max-width: 50%"
            @feedback-value-changed="setMarketPricePerPartUserValue($event, 'market_price_pp')"
          />
        </div>
        <div class="w-1/3">
          <FeedbackValue
            :feedback-value="marketPricePerLot"
            no-heading
            field-name=""
            field-unit="€"
            field-type="validator"
            field-style="max-width: 50%"
            @feedback-value-changed="setMarketPricePerPartUserValue($event, 'market_price_pl')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

import FeedbackValue from '@/components/Reusable/FeedbackValues/FeedbackValue.vue';
import SparkInput from '@/components/SparkComponents/SparkInput.vue';
import useNumberFormatting from '@/composables/numberFormatting.js';

export default {
  name: 'MarketPrice',

  components: {
    FeedbackValue,
    SparkInput,
  },

  props: {
    chain: { type: Object, required: true },
  },

  data() {
    return {
      currentMargin: 20,
      usedPricingConfig: {},
    };
  },

  computed: {
    ...mapState(['user', 'actualMarketPriceRequest', 'investigationDetails', 'pricingOptions']),
    ...mapState('application', ['axiosInstance']),
    ...mapState({ storePart: 'part' }),
    ...mapGetters(['processChainsInitializationFinished']),

    processChainId() {
      return this.chain.process_chain_id;
    },

    part: {
      get() {
        return this.storePart;
      },

      set(value) {
        this.updatePart(value);
      },
    },

    boolProcessChainExists() {
      if (this.chain == null) {
        return false;
      }
      if (this.chain.cost != null) {
        return true;
      } else {
        return false;
      }
    },

    marketPricePerPart() {
      if (this.boolProcessChainExists) {
        return this.chain.market_price.market_price_pp;
      } else return {};
    },

    marketPricePerLot() {
      if (this.boolProcessChainExists) {
        return this.chain.market_price.market_price_pl;
      } else return {};
    },

    grossMargin() {
      const pricePerPart = this.marketPricePerPart.user
        ? this.marketPricePerPart.user
        : this.marketPricePerPart.calculated;

      const margin = (pricePerPart / this.amCostPerPart - 1) * 100;
      return Math.round(margin);
    },

    costingConfig() {
      let costingConfig = {};
      if (this.user.organization != undefined && this.user.organization.costing_configs != undefined) {
        if (this.chain != undefined) {
          for (const [key, value] of Object.entries(this.user.organization.costing_configs)) {
            if (key == this.chain.costing_config_id) {
              costingConfig = value;
            }
          }
        }
        // ! correct handling of costingConfig has to be checked in the template section !
      }
      return costingConfig;
    },

    amCostPerPart() {
      if (this.boolProcessChainExists) {
        return this.chain.cost.cost_pp;
      }
      return 0;
    },

    amCostPerLot() {
      if (this.boolProcessChainExists) {
        return this.chain.cost.cost_pl;
      }
      return 0;
    },
  },

  watch: {
    costingConfig: {
      handler() {
        this.updateMargin();
      },

      immediate: true,
    },
  },

  mounted() {
    this.findUsedPricingConfig();
  },

  methods: {
    ...mapMutations([
      'setActualMarketPriceRequest',
      'updatePart',
      'updateSingleProcessChain',
      'updateMarketPricePerPartUserValue',
    ]),

    updateMargin() {
      if (this.boolProcessChainExists && this.chain.margin_user_value !== null) {
        this.currentMargin = useNumberFormatting(this.chain.margin_user_value * 100, 0).number;
      } else if (this.costingConfig && this.costingConfig.margin_default !== undefined) {
        this.currentMargin = useNumberFormatting(this.costingConfig.margin_default * 100, 0).number;
      }
    },

    findUsedPricingConfig() {
      this.usedPricingConfig = this.pricingOptions.find(config => config.uid === this.chain.pricing_config_id);
    },

    setMarketPricePerPartUserValue(user_value, field) {
      this.updateMarketPricePerPartUserValue({ uid: this.chain.process_chain_id, value: user_value });

      this.savePart({
        [field]: this.chain.market_price.market_price_pp,
      });
    },

    async savePart(formData) {
      await this.axiosInstance
        .put(`/api/v1/process-chain/${this.processChainId}/`, formData)
        .then(response => {
          this.updateSingleProcessChain(response.data);
        })
        .catch(error => {
          console.log(JSON.stringify(error));
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.spark-table {
  margin: unset;
}

.price-header {
  font-weight: bold;
}

.right-aligned {
  text-align: right;
}

.grey {
  color: var(--spark-lightgrey);
  width: 35px !important;
}

input[type='range'] {
  z-index: 5;
  position: relative;
  appearance: none;
  background-color: #bdc3c7;
  width: 150px;
  height: 5px;
  border-radius: 5px;
  margin: 0 auto;
  outline: 0;
  opacity: 0.15;

  &:hover {
    height: 5px;
    opacity: 1;
  }

  &:before {
    opacity: 0;
  }
}

label {
  text-align: left;
}

input[type='range']::-webkit-slider-thumb {
  appearance: none;
  background-color: var(--primary-color);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid white;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  &:active {
    transform: scale(1.15);
  }
}

button:disabled {
  opacity: 0.5;

  &:hover {
    color: black;
    cursor: default;
  }
}

input[type='range']::-webkit-slider-thumb:hover {
  background-color: rgb(255, 0, 0);
  border: 2px solid #e74c3c;
}

/* Hide arrows in number input field in Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* ... and Firefox */
input[type='number'] {
  appearance: textfield;
}
</style>
