<template>
  <div>
    <div v-if="!boolUserInput && !noHeading" class="estimation inline-block">
      &nbsp;&nbsp;&nbsp;Pricing by Configuration
    </div>
    <div v-else-if="!noHeading" class="estimation inline-block">
      <div class="user-validation inline-block">
        <i class="fas fa-check user-validation-icon" />
        <div class="inline-block" />
        validated by user
      </div>
    </div>
    <div class="flex items-center">
      <input
        ref="feedbackInputField"
        v-model="storedFeedbackValue"
        :style="fieldStyle"
        class="border-white text-13 hover:border-gray-300 text-right hover rounded-8 focus:border-gray-600 active:border-gray-600 font-bold p-4 mb-2"
        type="number"
        step="1"
        min="0"
        max="1000000"
        :placeholder="storedCalculatedValue"
        :title="lockedForUser ? lockedTitle : ''"
        :disabled="lockedForUser"
        :class="{ '!w-[100px]': noHeading }"
        @change="setNewFeedbackValue()"
        @blur="
          resetFeedbackValue();
          inputFocusActive(false);
        "
        @focus="inputFocusActive(true)"
      />
      <div class="pl-4">{{ fieldUnit }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import { floatFormat } from '@/helpers.js';

export default {
  name: '',

  props: {
    feedbackValue: {
      type: Object,
      default() {
        return {};
      },
    }, // an object with .user & .calculated entries or a plain value if no validator field (e.g. for time)

    fieldUnit: { type: String, default: '' },
    fieldStyle: { type: String, default: '' },
    noHeading: { type: Boolean },
  },

  emits: ['feedback-value-changed', 'input-focus-active'],

  data() {
    return {
      storedFeedbackValue: '0',
      storedCalculatedValue: 0,
      boolUserInput: false,
      feedbackValueReset: false,
    };
  },

  computed: {
    ...mapState('application', ['lockedTitle']),
    ...mapGetters(['lockedForUser']),
  },

  watch: {
    feedbackValue: {
      handler(feedbackValue) {
        this.setProppedFeedbackValue(feedbackValue);
        this.calculateBoolUserInput(feedbackValue);
      },

      immediate: true,
    },
  },

  methods: {
    calculateBoolUserInput(feedbackValue) {
      // returns true if a validator feedback field has an user input and otherwise false
      if (feedbackValue != undefined) {
        if (feedbackValue.user === null) {
          this.boolUserInput = false;
        } else {
          this.boolUserInput = true;
        }
      } else {
        this.boolUserInput = false;
      }
    },

    setProppedFeedbackValue(feedbackValue) {
      this.storedCalculatedValue = this.roundValue(feedbackValue.calculated, this.getDigits());
      // if (this.boolProcessChainExists) {
      // if the user_value is null there is no user input yet -> return calculated
      if (!feedbackValue.user) {
        this.storedFeedbackValue = this.roundValue(feedbackValue.calculated, this.getDigits());
        this.feedbackValueReset = false;
      } else {
        this.storedFeedbackValue = this.roundValue(feedbackValue.user, this.getDigits());
      }
      // if there is no process chain yet at least 0 should be returned
      // } else {
      //   this.storedFeedbackValue = 0;
      // }
    },

    setNewFeedbackValue(resetBool = false) {
      // Make sure to save only mindfull user inputs
      // if value is string of length 0 the field was emptied and the calculated value should be restored
      // otherwise .lenght will deliver an undefined

      if (this.storedFeedbackValue.length === 0 || resetBool) {
        this.$refs.feedbackInputField.focus();
        if (this.boolUserInput) {
          this.$emit('feedback-value-changed', 0);
        }
        this.storedFeedbackValue = '';
        this.boolUserInput = false;
        return;
      }

      this.boolUserInput = true;

      this.storedFeedbackValue === 0 ? (this.storedFeedbackValue = 0.0) : '';
      this.storedFeedbackValue = Math.abs(this.storedFeedbackValue);

      /* emitted value has to be:
      1. saved to store in parent component
      2. savePart() (part or process_chain_api) to backend
      */
      this.storedFeedbackValue = this.roundValue(this.storedFeedbackValue, this.getDigits());
      this.$emit('feedback-value-changed', this.storedFeedbackValue);
    },

    getDigits() {
      // Set number of digits according to the fieldUnit
      let digits = 0;
      if (this.fieldUnit === '€') {
        digits = 2;
      } else {
        digits = 0;
      }
      return digits;
    },

    resetFeedbackValue() {
      if (!this.boolUserInput) {
        this.storedFeedbackValue = this.storedCalculatedValue;
      }
    },

    roundValue(number, digit = 2) {
      return parseFloat(floatFormat(number, digit)).toFixed(digit);
    },

    inputFocusActive(value) {
      this.$emit('input-focus-active', value);
    },
  },
};
</script>

<style lang="scss" scoped>
// * {
//   transition: all 0.3s ease-in;
// }

// .estimation {
//   display: inline-block;
//   margin-top: 8px;
//   font-size: 0.75em;

//   &:hover {
//     color: black;
//   }
// }

// .user-validation {
//   @extend .estimation;
//   margin-right: 5px;
//   margin-top: 0px;
//   font-size: 1em;

//   i {
//     opacity: 0;
//   }

//   &:hover {
//     color: var(--spark-check-green);
//     i {
//       opacity: 1;
//     }
//   }
// }

// .user-validation-icon {
//   margin-right: 5px;
// }

// .fa-times,
// .fa-check-validate {
//   display: inline-block;
//   color: var(--spark-lightgrey);
// }

// /* Only show icons on hover of input field and move text accordingly*/
// .edit-icons {
//   display: inline-block;
//   opacity: 0;
//   margin-left: -7px;
//   margin-right: 10px;

//   &:hover .fa-times {
//     color: var(--spark-red);
//   }
//   &:hover .fa-check-validate {
//     color: var(--spark-check-green);
//   }
// }

// .edit-icons:first-of-type {
//   margin-right: 15px;
// }

// .spark-hover-input:hover .edit-icons {
//   display: inline-block;
//   transition-delay: 0.2s;
//   opacity: 1;
// }

// input:focus ~ .edit-icons {
//   display: inline-block;
//   opacity: 1;
// }

/* --------------------------------------------------------------------------------------------------- */
/* Remove spinners to make the currency symbol appear right next to the cost value in Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

// input[type='number'] {
//   -moz-appearance: textfield;
//   -webkit-appearance: none;
//   appearance: textfield;
//   display: inline-block;

//   background-color: white;
//   border: solid 1px rgba(255, 255, 255, 0);
//   margin-bottom: 0;
//   font-size: 1em;
// }

// $hover-input-field-padding: 40px;

// .spark-hover-input:hover .hover-input-field {
//   display: inline-block;
//   padding-right: $hover-input-field-padding;
// }

// .spark-hover-input {
//   input {
//     display: inline-block;
//     text-align: center;
//     width: 130px;
//     border: solid 1px rgba(255, 255, 255, 0);
//     &:focus {
//       padding-right: $hover-input-field-padding;
//     }
//   }
// }
</style>
