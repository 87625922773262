import Admin from '../../views/Admin/Admin.vue';
import AuditLog from '../../views/Admin/AuditLog/AuditLog.vue';
import ErrorLog from '../../views/Admin/ErrorLog/AuditLog/ErrorLog.vue';
import UserActivity from '../../views/Admin/UserActivity/UserActivity.vue';
// import AdminTeam from '../../views/Account/adminteam/AdminTeam.vue';

const subNavigations = {
  admin: [
    {
      label: 'Audit Log',
      value: 'audit-log',
      to: { name: `audit-log` },
    },
    {
      label: 'User Activity',
      value: 'user-activity',
      to: { name: `user-activity` },
    },
    {
      label: 'Error Log',
      value: 'error-log',
      to: { name: `error-log` },
    },
    // {
    //   label: 'Admin Team',
    //   value: 'adminteam',
    //   to: { name: `adminteam` },
    // },
  ],
};

const routes = [
  {
    path: '/admin-view',
    redirect: '/admin-view/audit-log',
    component: Admin,
    meta: {
      title: 'User Activity',
      showSubNavigation: true,
      subNavigationHeading: 'User Activity',
      requireLogin: true,
      requireStaff: false,
      requireInternal: false,
      requiredRole: ['sparkmanager', 'sparkpoweruser', 'sparkuser'],
    },
    children: [
      {
        path: 'audit-log',
        name: 'audit-log',
        component: AuditLog,
        props: true,
        meta: {
          title: 'Audit Log | 3D Spark',
          requireLogin: true,
          requireStaff: false,
          requireInternal: false,
          requiredRole: ['sparkmanager'],
          subNavigation: subNavigations.admin,
        },
      },
      {
        path: 'error-log',
        name: 'error-log',
        component: ErrorLog,
        props: true,
        meta: {
          title: 'Audit Log | 3D Spark',
          requireLogin: true,
          requireStaff: true,
          requireInternal: false,
          requiredRole: ['sparkmanager'],
          subNavigation: subNavigations.admin,
        },
      },
      {
        path: 'user-activity',
        name: 'user-activity',
        component: UserActivity,
        props: true,
        meta: {
          title: 'User Activity | 3D Spark',
          requireLogin: true,
          requireStaff: true,
          requireInternal: true,
          requiredRole: ['sparkmanager'],
          subNavigation: subNavigations.admin,
        },
      },
      // {
      //   path: 'adminteam',
      //   name: 'adminteam',
      //   component: AdminTeam,
      //   meta: {
      //     requireLogin: true,
      //     requireStaff: false,
      //     requireInternal: false,
      //     title: 'Team',
      //     requiredRole: ['sparkmanager', 'sparkpoweruser', 'sparkuser'],
      //     subNavigation: subNavigations.account,
      //   },
      // },
    ],
  },
];

export default routes;
