<template>
  <SparkModal dismissable :model-value="show" @update:model-value="close">
    <template #header>
      <div>Add Process Step</div>
    </template>
    <template #default>
      <div class="flex flex-column mr-20 ml-20">
        <div class="mb-8">
          Go to the
          <span class="font-bold cursor-pointer" @click="gotoLibrary"
            ><i class="fas fa-arrow-right" /> process step library</span
          >
          to create new process steps
        </div>
        <div class="flex flex-row flex-wrap">
          <div v-for="template in filteredProcessStepTemplatess" :key="template" class="w-1/2 p-4">
            <div class="border border-gray-300 rounded-8">
              <div class="flex items-center justify-between mb-8 h-80 border-b border-gray-50">
                <div class="flex font-semibold ml-8 break-all">
                  {{ template.name }}
                </div>
                <div class="flex pr-4">
                  <div
                    v-if="!addedProcessStepTemplates.includes(template.uid)"
                    class="cursor-pointer hover:text-primary-500 mx-8"
                    @click="addProcessStep(template.uid)"
                  >
                    Add
                  </div>
                  <div v-else class="bg-primary-500 p-4 rounded-4 text-white flex mx-2 items-center">
                    <i class="fas fa-check" />Added
                  </div>
                </div>
              </div>

              <div class="spark-table">
                <tbody>
                  <tr>
                    <td class="text-gray-600">Restriction</td>
                    <td v-if="template.restriction == 'organization'"><i class="fas fa-user-friends" /></td>
                    <td v-else-if="template.restriction == 'owner'"><i class="fas fa-user" /></td>
                    <td v-else>-</td>
                    <td />
                  </tr>
                  <tr v-if="template.calc_lvl != 'expression'">
                    <td class="text-gray-600">Calculation Level</td>
                    <td>{{ getReadableCalcLvl(template.calc_lvl) }}</td>
                    <td />
                  </tr>
                  <tr v-else>
                    <td class="text-gray-600">Calculation Expression</td>
                    <td class="right">{{ template.calc_expression }}</td>
                    <td />
                  </tr>
                  <tr>
                    <td class="text-gray-600">Setup Time (hh:mm:ss)</td>
                    <td class="text-right">
                      <FeedbackValueTimespan :editable="false" :feedback-value="template?.setup_time" />
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <td class="text-gray-600">Process Time (hh:mm:ss)</td>
                    <td class="text-right">
                      <FeedbackValueTimespan :editable="false" :feedback-value="template?.process_time" />
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <td class="text-gray-600">Unloading Time (hh:mm:ss)</td>
                    <td class="text-right">
                      <FeedbackValueTimespan :editable="false" :feedback-value="template?.unloading_time" />
                    </td>
                    <td />
                  </tr>

                  <tr>
                    <td class="text-gray-600">Fixed Cost</td>
                    <td class="text-right">{{ template.fixed_cost }}</td>
                    <td>€</td>
                  </tr>
                  <tr>
                    <td class="text-gray-600">Machine Hourly Rate</td>
                    <td class="text-right">{{ template.machine_hourly_rate }}</td>
                    <td>€/h</td>
                  </tr>
                  <tr>
                    <td class="text-gray-600">Staff Time Factor</td>
                    <td class="text-right">{{ $formatZeroDecimalPlaces(template.staff_time_factor * 100) }}</td>
                    <td>%</td>
                  </tr>
                  <tr>
                    <td class="text-gray-600">Worker</td>
                    <td colspan="2">{{ getReadableWorkerType(template.worker_type) }}</td>
                  </tr>
                  <tr>
                    <td class="text-gray-600">CO2 Expression</td>
                    <td v-if="template.co2_expression" class="right">{{ template.co2_expression }}</td>
                    <td v-else>-</td>
                  </tr>
                </tbody>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </SparkModal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import FeedbackValueTimespan from '../../Reusable/FeedbackValues/FeedbackValueTimespan.vue';

import SparkModal from '@/components/SparkComponents/SparkModal.vue';
import useCopyObject from '@/composables/copyObject.js';

export default {
  name: 'ProcessStepDialog',

  components: { FeedbackValueTimespan, SparkModal },

  props: {
    additionalData: {
      type: Object,
      default() {
        return {};
      },
    },

    show: { type: Boolean },
  },

  emits: ['close', 'save'],

  data() {
    return {
      filteredProcessStepTemplatess: {},
      addedProcessStepTemplates: [],
    };
  },

  computed: {
    ...mapState(['workerTypes', 'calcLvls', 'user']),
    ...mapState('application', ['axiosInstance']),
  },

  watch: {
    additionalData() {
      this.filteredProcessStepTemplatess = {};
      this.getProcessStepTemplates();
    },
  },

  mounted() {
    this.getProcessStepTemplates();
  },

  methods: {
    ...mapMutations(['updatePart', 'triggerPopup']),

    gotoLibrary() {
      this.$router.push({ name: 'process-step-library' });
      this.triggerPopup('');
    },

    getReadableCalcLvl(calcLvl) {
      return Object.values(this.calcLvls).find(obj => {
        return obj.value === calcLvl;
      }).text;
    },

    getReadableWorkerType(workerType) {
      return Object.values(this.workerTypes).find(obj => {
        return obj.value === workerType;
      }).text;
    },

    filterByStepType(data) {
      return Object.fromEntries(
        Object.entries(data).filter(
          ([key, step]) =>
            step.step_type == this.additionalData.step_type &&
            (step.prc_acronym == this.additionalData.chain.prc_acronym) | (step.prc_acronym == null)
        )
      );
    },

    checkIfTemplatesAlreadyInProcessChainAdded(steps) {
      let array = [];
      Object.keys(steps).forEach(id => {
        array.push(steps[id].process_step_template_id);
      });
      return array;
    },

    async getProcessStepTemplates() {
      this.axiosInstance
        .get(`/api/v1/process-step-templates-all/`)
        .then(response => {
          let allProcessSteps = this.filterByStepType(response.data);
          this.filteredProcessStepTemplatess = useCopyObject(allProcessSteps);
          this.addedProcessStepTemplates = this.checkIfTemplatesAlreadyInProcessChainAdded(
            this.additionalData.chain.process_steps
          );
        })
        .catch(error => {
          console.log(JSON.stringify(error));
        });
    },

    addProcessStep(uid) {
      let formData = new FormData();
      formData.append('process_step_template_uid', uid);
      formData.append('process_chain_id', this.additionalData.chain.process_chain_id);

      this.axiosInstance
        .post(`/api/v1/process-step/`, formData)
        .then(response => {
          this.updatePart(response.data);
          this.addedProcessStepTemplates.push(uid);
        })
        .catch(error => {
          this.$root.notifyError('Adding Process Step', error);
        });
    },

    close() {
      this.$emit('close');
    },
  },
};
</script>
