<template>
  <div v-if="showSubNavigation" class="sub-navigation sticky z-40 bg-white grid flex-shrink-0 grid-cols-3 px-20">
    <div
      class="flex item-center gap-16 flex-wrap w-full"
      :class="{ 'justify-between': showTabs, 'justify-start': !showTabs }"
    >
      <div v-if="isPrpRoute" class="flex items-center gap-20">
        <div class="text-19 font-semibold" v-text="heading" />
      </div>
      <spark-tabs v-else-if="showTabs" :items="items" class="custom-scrollbar">
        <template #default="{ item }">
          <router-link v-slot="{ isActive }" custom :to="item.to">
            <div
              class="sub-navigation__item"
              :class="{
                item__active: isActive,
                isActive,
                item__inactive: !isActive,
              }"
              @click="() => onClick(item)"
              v-text="item.label"
            />
          </router-link>
        </template>
      </spark-tabs>

      <div id="sub-navigation__action-buttons" class="flex items-center" />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import SparkTabs from '@/components/SparkComponents/SparkTabs.vue';

export default {
  name: 'SubNavigation',

  components: { SparkTabs },

  computed: {
    ...mapState(['user']),
    ...mapState('application', ['initializationStatus']),

    items() {
      let items = this.$route.meta.subNavigation ?? [];

      if (this.user.is_external) {
        return [
          {
            label: 'Parts',
            value: 'part',
            to: { name: `part-library` },
          },
        ];
      }
      if (!this.user.is_usermanager) {
        return items.filter(item => item.value !== 'team');
      }

      return items;
    },

    showTabs() {
      return this.items.length > 0;
    },

    showSubNavigation() {
      return this.$route.meta.showSubNavigation || false;
    },

    isPrpRoute() {
      return this.$route.name === 'prp';
    },

    heading() {
      return this.$route.meta.subNavigationHeading;
    },
  },

  methods: {
    ...mapMutations(['setReloadPartLibrary']),

    onClick(item) {
      if (item.to.name === this.$route.name && item.to.name === 'part-library') {
        this.setReloadPartLibrary();
      }
      this.$router.push(item.to);
    },

    setReloadPartLibrary() {
      this.$store.commit('setReloadPartLibrary', true);
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-navigation {
  border: 1px solid #e5e7eb;
  display: flex;
  width: 100%;
  gap: 40px;
  padding: 10px;
  min-height: 50px;
  top: 56px;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: black transparent;
  display: flex;
  gap: 16px;
  overflow-x: auto;
  align-self: center;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 12px;
}

.sub-navigation__item {
  border-radius: 8px;
  padding: 8px 20px;
  cursor: pointer;
}

.item__active {
  font-weight: bold;
  background-color: #f3f4f6;
}
.item__inactive {
  color: #9ca3af;
  &:hover {
    background-color: #e5e7eb;
  }
}
</style>
